@font-face {
    font-family: Font1;
    src: url('https://d1hfsnuz4i23pd.cloudfront.net/public/2024-02-21_04:47:28.349-KoHo-Bold.ttf') format("opentype");
}

@font-face {
    font-family: Font2;
    src: url('https://tabin-public.s3.ap-southeast-2.amazonaws.com/restaurant-fonts/telegraf/PPTelegraf-Regular.otf') format("opentype");
}

@font-face {
    font-family: Font3;
    src: url('https://tabin-public.s3.ap-southeast-2.amazonaws.com/restaurant-fonts/telegraf/PPTelegraf-UltraBold.otf') format("opentype");
}

:root {
    --primary-color: #28CED2;
    --secondary-color: #28CED2;
    --background-color: #083431;
    --font-color: #FFFFFF;
}

body {
    font-family: 'Font2', Fallback, sans-serif;
    background-color: var(--background-color);
    color: var(--font-color);
}

.restaurant-list {
    display: flex;
    flex-direction: column;
    padding: 24px;
    max-width: 600px;
    width: 100%;
    margin: auto;
}

.restaurant-list-logo {
    width: 40%;
    margin: 0 auto;
    margin-bottom: 32px;
    border-radius: 10px;
}

.restaurant-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.description {
    color: #FFFFFF;
}

.restaurant-name {
    font-size: 24px;
    color: #FFFFFF;
}

.button {
    display: flex;
    justify-content: center;
    padding: 16px 24px;
    border-radius: 3px;
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: 1px solid var(--primary-color);
}

.button.disabled {
    cursor: default;
    background-image: none;
    background-color: hsl(0, 0%, 70%);
}